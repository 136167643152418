<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.my_statement')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">{{ $t("withdraw.task_money") }}({{ $t("reservation.unit") }})</p>
        <p class="value">{{ new Number(this.win_money) | numyn }}</p>
        <p class="tip">Rumus keuntungan:<br/> Jumlah Kemenangan - Jumlah Tugas</p>
      </div>
      <div class="content">
        <div class="datalist">
          <div class="datalistitem">
            <div class="datalistitemValue">{{ new Number(this.personalreport.play_money) | numyn }}</div>
            <div class="datalistitemKey">{{ $t("withdraw.task_money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ new Number(this.personalreport.recharge) | numyn }}</div>
            <div class="datalistitemKey">{{ $t("withdraw.recharge_money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ new Number(this.personalreport.withdrawal) | numyn }}</div>
            <div class="datalistitemKey">{{ $t("withdraw.money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ new Number(this.personalreport.win_money) | numyn }}</div>
            <div class="datalistitemKey">{{ $t("withdraw.win_money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      win_money: 0,
      personalreport: {}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getPersonalreport() {
      this.$http({
        method: 'get',
        url: 'user_get_personalreport'
      }).then(res => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money = this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getPersonalreport();
    }
  },
  filters: {
    numyn: (val) => {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .header {
  background: linear-gradient(90deg, #66bdfb, #67d7d9);
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}

.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}

.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}

.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
  text-align: center;
  line-height: 50px;
}

.container .content {
  flex: 1;
  background: #f2f2f5;
}

.container .content .datalist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.container .content .datalist .datalistitem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 45%;
}

.container .content .datalist .datalistitem .datalistitemValue {
  color: #ff253f;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.container .content .datalist .datalistitem .datalistitemKey {
  color: #979799;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}</style>
