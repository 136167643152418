<template>
  <div class="container page">
    <van-nav-bar :title="$t('my.task_record')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="Gulir ke bawah untuk menyegarkan..."
        loosing-text="Lepaskan untuk menyegarkan..." loading-text="Muat ulang">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <span class="period-number">{{ v.expect }}</span>
          </div>
          <div class="lottery_info2">
            <span class="period-number2">{{ v.name }}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <span class="res-des middle">{{ v.status === 0 ? "***" : v.sumText }}</span>
              <span class="res-des middle">{{ v.status === 0 ? "***" : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >=
                11 ? "Timur" : "Barat" }}</span>
              <span class="res-des middle">{{ v.status === 0 ? "***" : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2
                === 0 ? "Utara" : "Selatan" }}</span>
            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{ v.status_text }}</span>
            <span v-else>{{ v.status_text }}</span>
            <span>Skor total pesanan：{{ numyn(v.money) }}Rp</span>
          </div>
          <div class="touzhu bd-b " v-for="(iten, index) in v.data" :key="index">
            <div class="time">
              <span>Jenis pesanan: <strong style="font-size: 16px;">{{ iten.type }}</strong></span>
            </div>
            <div class="time">
              <span>Poin untuk satu pesanan: <strong style="font-size: 16px;">{{ numyn(iten.money) }} Rp</strong></span>
            </div>
            <div class="time">
              <span>Detail Keuntungan:<strong style="font-size: 16px;" :class="iten.is_win==2?'die':'zhang'">
                {{iten.is_win==1?'+':iten.is_win==2?'-':''}} 
                {{ iten.profit && iten.is_win!=0?numyn(iten.profit)+' Rp':"Tunggu auditnya" }} </strong></span>
            </div>
            <div class="time">
              <span>Waktu pemesanan Selatan: {{ iten.create_time }}</span>
            </div>
          </div>
          <div class="time">
              <span>Waktu pembayaran:{{ v.update_time }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    numyn(val) {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    },
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
        this.getUserGameList()
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          let listArr = res.data;
          listArr.forEach(element => {
            if (element.opencode) {
              let sum = element.opencode[0] + element.opencode[1] + element.opencode[2];
              let size = ""
              let double = ""
              if (sum >= 11) {
                size = 'Timur';
              } else if (sum >= 3 && sum <= 10) {
                size = 'Barat';
              }
              if (sum % 2 === 0) {
                double = 'Utara';
              } else {
                double = 'Selatan';
              }
              element.sumText = size + " " + double;
            }
          });
          this.list = listArr;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserGameList();
    }
  }
};
</script>
<style>
.van-image__img {
  border-radius: 10px;
}
</style>
<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo {
  width: 100%;
  height: 60px;
  margin-top: 20px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}

.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: left;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.item_list .period-number {
  margin-left: 10px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;

  &:nth-of-type(2) {
    margin-left: 50px;
    font-size: 20px;
    float: right;
  }
}

.item_list .lottery_info {
  display: flex;
}

.recent {
  display: flex;
  align-items: center;
  height: 100px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;

  &:nth-of-type(1) {
    margin-left: 50px;
  }
}

.kuaisan-ball .res-des {
  font-weight: 900;
  text-align: center;
  color: #000;
  font-size: 38px !important;
  &:nth-of-type(1) {
    margin-left: 10px;
  }
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 30px;

  &:nth-of-type(1) {
    width: 25%;
  }
}

.lottery_info2 {
  width: 100%;

  .period-number2 {
    font-size: 36px;
  }
}

.res-des {
  margin: 0 20px;
}
.touzhu{
  margin: 10px 0;
}
.bd-b {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: -webkit-linear-gradient(
      bottom,
      #e2e2e2,
      #e2e2e2 50%,
      transparent 50%
    );
    background-image: linear-gradient(
      0deg,
      #e2e2e2,
      #e2e2e2 50%,
      transparent 50%
    );
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: bottom left;
  }
}
.zhang{
  color: #00973a;
}
.die{
  color: #ff0202;
}
</style>
