<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">
          {{ $t("recharge.curr_balance") }}({{ $t("reservation.unit") }})
        </p>
        <p class="value">{{ this.balance }}</p>
      </div>
      <div class="content recharge">
        <van-form @submit="onSubmit">
          <div class="form-item">
            <div class="form-item-title">{{ $t("recharge.input_money") }}</div>
            <div style="height: 65px">
              <van-field
                v-model="money"
                name="money"
                label="Rp"
                style="font-size: 16px;"
                :placeholder="$t('recharge.input_money')"
              />
            </div>
          </div>
          <div class="form-item">
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
                >kirim</van-button>
            </div>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
  data() {
    return {
      balance: 0,
      pay_way: "Mexicopay",
      win_money: 0,
      money: "",
      personalreport: {},
    };
  },
  mounted() {
    this.balance = this.numyn(this.$route.query.balance)
  },
  methods: {
    numyn(val) {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    },
    back() {
      return window.history.back();
    },

    //拉起支付
    onSubmit() {
      this.$toast("Silakan hubungi layanan pelanggan");
    },

    getPersonalreport() {
      this.$http({
        method: "get",
        url: "user_get_personalreport",
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getPersonalreport();
    }
  },
};
</script>
  
  <style lang='less' scoped>
@import "../../assets/css/base.css";
.container .header {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}
.recharge {
  padding: 10px 30px;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}
.van-button__text {
  color: #fff !important;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}
/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 35px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}
.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}
.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}
.container .content {
  flex: 1;
  background: #f2f2f5;
}
</style>
  